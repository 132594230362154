.mwarns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.m-cross {
    cursor: pointer;
}
.m-cross:before {
    content: '❌';
}

.m-avatar {
    border-radius: 45%;
    width: 28px;
    height: 28px;
}
.m-flag {
    display: inline-block;
    min-width: 2em;
    text-align: center;
    font-family: babel-stone-flags;
}

.mspinform {
    display: flex;
    flex-direction: column;
}
.m-form {
    display: flex;
    flex-direction: column;
}
.table-dark {
   color: var(--m-color-txt);
   --bs-table-bg:transparent;
    border-color: var(--m-color-border);
}
.dropdown-menu-dark {
    --bs-dropdown-bg:var(--m-bg-menubot) ;
    --bs-dropdown-link-hover-bg:var(--m-bg-light);
    --bs-dropdown-link-hover-color:var(--m-color-txt);
}
.m-image {
    border: 1px solid  var(--m-color-border,orangered);
}
.m-image:hover{
    transform: scale(2);
}

.m-btn-info {
    background:  linear-gradient(to top, var(--m-bg0-color), var(--m-bg1-color));
    font-family: babel-stone-flags;
    color: var(--m-color-txt);
    padding: 0.2rem 0.4rem;
    border-radius: .5em;
    border: 1px solid silver;
    display: inline-block;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
}
.m-btn-info:after {
    content: ' ❓ ';
}
.form-control::file-selector-button,
input::file-selector-button {
    background: linear-gradient('to-top',var(--m-bg0-color),var(--m-bg1-color) );
    color: var(--m-color-txt);
    background-color: inherit;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button
{
    background: linear-gradient('to-bottom',var(--m-bg0-color),var(--m-bg1-color) );
    color: var(--m-color-txt);
    background-color: inherit;

}
.m-report {
    border: 1px solid var(--m-color-border);
    display: flex;
    flex-direction: column;
    padding: 2px;
    width: 330px;
    border-radius: .5em;
    background-color: var(--m-bg-light);
}
.m-report-wide > span,
.m-report > span {
    text-align: center;
    border-bottom: 1px dotted;
    padding: 4px;
    color: var(--m-color-txt);
}
.m-report-wide {
    border: 1px solid var(--m-color-border);
    margin: 1em .5em;
    display: flex;
    flex-direction: column;
    padding: 2px;
    border-radius: .5em;
    height: 100%;
    background-color: var(--m-bg-light);
}

.widget-icon {
    font-size: 20px;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 3px;
    display: inline-block;
    opacity: .6;
}
.bg-m-success {
    background: linear-gradient(to bottom,hsl(120, 100%, 90%),hsl(120, 100%, 95%) );
    color: var(--bs-success);
}
.bg-m-danger {
    background: linear-gradient(to bottom,hsl(0, 100%, 90%),hsl(0, 100%, 95%) );
    color: var(--bs-danger);
}
.bg-m-info {
    background: linear-gradient(to bottom,hsl(168, 100%, 85%),hsl(168, 100%, 90%) );
    color: var(--bs-info);
}
.bg-m-primary {
    background: linear-gradient(to bottom,hsl(204, 100%, 80%),hsl(204, 100%, 90%) );
    color: var(--bs-primary);
}