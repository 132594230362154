.m-bs-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
.m-bs-checkbox__input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
.m-bs-checkbox__input:focus {
    z-index: -1;
}
.m-bs-checkbox__ico {
    width: var(--bs-body-font-size,1.5em);
    height: var(--bs-body-font-size,1.5em);
    border: 2px solid var(--m-color-border,silver);
    border-radius: 4px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin: 0 .2em;
    transition: background .2s, color .2s;
}
.m-bs-checkbox__ico::after {
    content: '\2714';
    transform: scale(0);
    transition: transform .2s;
}
.m-bs-checkbox__input:checked + .m-bs-checkbox__ico {
    color: var(--m-color-dark,black);
    background-color: var( --m-color-ok2,lightgreen);
}
.m-bs-checkbox__input:checked + .m-bs-checkbox__ico::after {
    transform: scale(1);
}
.m-bs-checkbox__input:focus + .m-bs-checkbox__ico {
    box-shadow: 0 0 2px 3px var(--m-color-bg2,silver);
}
